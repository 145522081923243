export const AppNav = () => import('../../components/appnav/AppNav.vue' /* webpackChunkName: "components/app-nav" */).then(c => wrapFunctional(c.default || c))
export const AppNavOverlay = () => import('../../components/appnav/AppNavOverlay.vue' /* webpackChunkName: "components/app-nav-overlay" */).then(c => wrapFunctional(c.default || c))
export const MobileLocalePop = () => import('../../components/appnav/MobileLocalePop.vue' /* webpackChunkName: "components/mobile-locale-pop" */).then(c => wrapFunctional(c.default || c))
export const NavIcons = () => import('../../components/appnav/NavIcons.vue' /* webpackChunkName: "components/nav-icons" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconContact = () => import('../../components/icons/IconContact.vue' /* webpackChunkName: "components/icon-contact" */).then(c => wrapFunctional(c.default || c))
export const IconLang = () => import('../../components/icons/IconLang.vue' /* webpackChunkName: "components/icon-lang" */).then(c => wrapFunctional(c.default || c))
export const IconLocation = () => import('../../components/icons/IconLocation.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c))
export const IconMenuClose = () => import('../../components/icons/IconMenuClose.vue' /* webpackChunkName: "components/icon-menu-close" */).then(c => wrapFunctional(c.default || c))
export const IconMenuOpen = () => import('../../components/icons/IconMenuOpen.vue' /* webpackChunkName: "components/icon-menu-open" */).then(c => wrapFunctional(c.default || c))
export const IconNext = () => import('../../components/icons/IconNext.vue' /* webpackChunkName: "components/icon-next" */).then(c => wrapFunctional(c.default || c))
export const IconPrev = () => import('../../components/icons/IconPrev.vue' /* webpackChunkName: "components/icon-prev" */).then(c => wrapFunctional(c.default || c))
export const CampaignPageImagePanelsSection = () => import('../../components/news-events/CampaignPageImagePanelsSection.vue' /* webpackChunkName: "components/campaign-page-image-panels-section" */).then(c => wrapFunctional(c.default || c))
export const CampaignPageSectionHeader = () => import('../../components/news-events/CampaignPageSectionHeader.vue' /* webpackChunkName: "components/campaign-page-section-header" */).then(c => wrapFunctional(c.default || c))
export const NewsDropDown = () => import('../../components/news-events/NewsDropDown.vue' /* webpackChunkName: "components/news-drop-down" */).then(c => wrapFunctional(c.default || c))
export const NewsOption = () => import('../../components/news-events/NewsOption.vue' /* webpackChunkName: "components/news-option" */).then(c => wrapFunctional(c.default || c))
export const ProductListTile = () => import('../../components/product/ProductListTile.vue' /* webpackChunkName: "components/product-list-tile" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const ButtonLink = () => import('../../components/ButtonLink.vue' /* webpackChunkName: "components/button-link" */).then(c => wrapFunctional(c.default || c))
export const DropdownBar = () => import('../../components/DropdownBar.vue' /* webpackChunkName: "components/dropdown-bar" */).then(c => wrapFunctional(c.default || c))
export const DynamicLink = () => import('../../components/DynamicLink.vue' /* webpackChunkName: "components/dynamic-link" */).then(c => wrapFunctional(c.default || c))
export const Foldable = () => import('../../components/Foldable.vue' /* webpackChunkName: "components/foldable" */).then(c => wrapFunctional(c.default || c))
export const GeneralCarouselWrapper = () => import('../../components/GeneralCarouselWrapper.vue' /* webpackChunkName: "components/general-carousel-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ImageSlide = () => import('../../components/ImageSlide.vue' /* webpackChunkName: "components/image-slide" */).then(c => wrapFunctional(c.default || c))
export const Item = () => import('../../components/Item.vue' /* webpackChunkName: "components/item" */).then(c => wrapFunctional(c.default || c))
export const Kicker = () => import('../../components/Kicker.vue' /* webpackChunkName: "components/kicker" */).then(c => wrapFunctional(c.default || c))
export const LineThroughText = () => import('../../components/LineThroughText.vue' /* webpackChunkName: "components/line-through-text" */).then(c => wrapFunctional(c.default || c))
export const LocalePopup = () => import('../../components/LocalePopup.vue' /* webpackChunkName: "components/locale-popup" */).then(c => wrapFunctional(c.default || c))
export const Markdown = () => import('../../components/Markdown.vue' /* webpackChunkName: "components/markdown" */).then(c => wrapFunctional(c.default || c))
export const PageNavbar = () => import('../../components/PageNavbar.vue' /* webpackChunkName: "components/page-navbar" */).then(c => wrapFunctional(c.default || c))
export const PageSections = () => import('../../components/PageSections.vue' /* webpackChunkName: "components/page-sections" */).then(c => wrapFunctional(c.default || c))
export const RemoveMarkdown = () => import('../../components/RemoveMarkdown.vue' /* webpackChunkName: "components/remove-markdown" */).then(c => wrapFunctional(c.default || c))
export const SalonInfoPreview = () => import('../../components/SalonInfoPreview.vue' /* webpackChunkName: "components/salon-info-preview" */).then(c => wrapFunctional(c.default || c))
export const SectionHeading = () => import('../../components/SectionHeading.vue' /* webpackChunkName: "components/section-heading" */).then(c => wrapFunctional(c.default || c))
export const SectionSubheading = () => import('../../components/SectionSubheading.vue' /* webpackChunkName: "components/section-subheading" */).then(c => wrapFunctional(c.default || c))
export const SlopedCarousel = () => import('../../components/SlopedCarousel.vue' /* webpackChunkName: "components/sloped-carousel" */).then(c => wrapFunctional(c.default || c))
export const SocialButtons = () => import('../../components/SocialButtons.vue' /* webpackChunkName: "components/social-buttons" */).then(c => wrapFunctional(c.default || c))
export const VideoSlide = () => import('../../components/VideoSlide.vue' /* webpackChunkName: "components/video-slide" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/YoutubeVideo.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const ContentfulImage = () => import('../../components/contentful/Image.vue' /* webpackChunkName: "components/contentful-image" */).then(c => wrapFunctional(c.default || c))
export const ContentfulPageThumbnail = () => import('../../components/contentful/PageThumbnail.vue' /* webpackChunkName: "components/contentful-page-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const FooterActionShortcutSection = () => import('../../components/footer/ActionShortcutSection.vue' /* webpackChunkName: "components/footer-action-shortcut-section" */).then(c => wrapFunctional(c.default || c))
export const FooterDesktopMenu = () => import('../../components/footer/DesktopMenu.vue' /* webpackChunkName: "components/footer-desktop-menu" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterMobileMenu = () => import('../../components/footer/MobileMenu.vue' /* webpackChunkName: "components/footer-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterScrollToTopBar = () => import('../../components/footer/ScrollToTopBar.vue' /* webpackChunkName: "components/footer-scroll-to-top-bar" */).then(c => wrapFunctional(c.default || c))
export const HomeSectionHeader = () => import('../../components/home/SectionHeader.vue' /* webpackChunkName: "components/home-section-header" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/icon/ArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const ItemCard = () => import('../../components/item/Card.vue' /* webpackChunkName: "components/item-card" */).then(c => wrapFunctional(c.default || c))
export const ItemImage = () => import('../../components/item/Image.vue' /* webpackChunkName: "components/item-image" */).then(c => wrapFunctional(c.default || c))
export const ItemOverlaidImageLink = () => import('../../components/item/OverlaidImageLink.vue' /* webpackChunkName: "components/item-overlaid-image-link" */).then(c => wrapFunctional(c.default || c))
export const ItemYoutubeVideo = () => import('../../components/item/YoutubeVideo.vue' /* webpackChunkName: "components/item-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const PageBeautyCreatorsInspire = () => import('../../components/page/BeautyCreatorsInspire.vue' /* webpackChunkName: "components/page-beauty-creators-inspire" */).then(c => wrapFunctional(c.default || c))
export const PageBeautyStream = () => import('../../components/page/BeautyStream.vue' /* webpackChunkName: "components/page-beauty-stream" */).then(c => wrapFunctional(c.default || c))
export const PageEditorial = () => import('../../components/page/Editorial.vue' /* webpackChunkName: "components/page-editorial" */).then(c => wrapFunctional(c.default || c))
export const PageGeneric = () => import('../../components/page/Generic.vue' /* webpackChunkName: "components/page-generic" */).then(c => wrapFunctional(c.default || c))
export const PageInspiration = () => import('../../components/page/Inspiration.vue' /* webpackChunkName: "components/page-inspiration" */).then(c => wrapFunctional(c.default || c))
export const SalonInfoPreviewRow = () => import('../../components/salon-info-preview/Row.vue' /* webpackChunkName: "components/salon-info-preview-row" */).then(c => wrapFunctional(c.default || c))
export const SalonLocatorJa = () => import('../../components/salon-locator/ja.vue' /* webpackChunkName: "components/salon-locator-ja" */).then(c => wrapFunctional(c.default || c))
export const SalonLocatorKo = () => import('../../components/salon-locator/ko.vue' /* webpackChunkName: "components/salon-locator-ko" */).then(c => wrapFunctional(c.default || c))
export const SectionButtonOnly = () => import('../../components/section/ButtonOnly.vue' /* webpackChunkName: "components/section-button-only" */).then(c => wrapFunctional(c.default || c))
export const SectionCascadedMediaLeft = () => import('../../components/section/CascadedMediaLeft.vue' /* webpackChunkName: "components/section-cascaded-media-left" */).then(c => wrapFunctional(c.default || c))
export const SectionCompact4Images = () => import('../../components/section/Compact4Images.vue' /* webpackChunkName: "components/section-compact4-images" */).then(c => wrapFunctional(c.default || c))
export const SectionFullscreenCarousel = () => import('../../components/section/FullscreenCarousel.vue' /* webpackChunkName: "components/section-fullscreen-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionFullscreenYoutubeVideo = () => import('../../components/section/FullscreenYoutubeVideo.vue' /* webpackChunkName: "components/section-fullscreen-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const SectionGrid = () => import('../../components/section/Grid.vue' /* webpackChunkName: "components/section-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionHeritage = () => import('../../components/section/Heritage.vue' /* webpackChunkName: "components/section-heritage" */).then(c => wrapFunctional(c.default || c))
export const SectionImageLink = () => import('../../components/section/ImageLink.vue' /* webpackChunkName: "components/section-image-link" */).then(c => wrapFunctional(c.default || c))
export const SectionNavigationBar = () => import('../../components/section/NavigationBar.vue' /* webpackChunkName: "components/section-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const SectionPerson = () => import('../../components/section/Person.vue' /* webpackChunkName: "components/section-person" */).then(c => wrapFunctional(c.default || c))
export const SectionPseudo = () => import('../../components/section/Pseudo.vue' /* webpackChunkName: "components/section-pseudo" */).then(c => wrapFunctional(c.default || c))
export const SectionSpiralGrid = () => import('../../components/section/SpiralGrid.vue' /* webpackChunkName: "components/section-spiral-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionStack = () => import('../../components/section/Stack.vue' /* webpackChunkName: "components/section-stack" */).then(c => wrapFunctional(c.default || c))
export const SectionStackWithMediaRight = () => import('../../components/section/StackWithMediaRight.vue' /* webpackChunkName: "components/section-stack-with-media-right" */).then(c => wrapFunctional(c.default || c))
export const SectionText = () => import('../../components/section/Text.vue' /* webpackChunkName: "components/section-text" */).then(c => wrapFunctional(c.default || c))
export const SectionTreatment = () => import('../../components/section/Treatment.vue' /* webpackChunkName: "components/section-treatment" */).then(c => wrapFunctional(c.default || c))
export const SectionTwoColumns = () => import('../../components/section/TwoColumns.vue' /* webpackChunkName: "components/section-two-columns" */).then(c => wrapFunctional(c.default || c))
export const SectionYoutubeVideo = () => import('../../components/section/YoutubeVideo.vue' /* webpackChunkName: "components/section-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const StaticSectionAboutUsShowcase = () => import('../../components/static-section/AboutUsShowcase.vue' /* webpackChunkName: "components/static-section-about-us-showcase" */).then(c => wrapFunctional(c.default || c))
export const SectionAboutUsJumbotron = () => import('../../components/section/about-us/Jumbotron.vue' /* webpackChunkName: "components/section-about-us-jumbotron" */).then(c => wrapFunctional(c.default || c))
export const SectionAboutUsSlogan = () => import('../../components/section/about-us/Slogan.vue' /* webpackChunkName: "components/section-about-us-slogan" */).then(c => wrapFunctional(c.default || c))
export const SectionAboutUsTable = () => import('../../components/section/about-us/Table.vue' /* webpackChunkName: "components/section-about-us-table" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyCreatorCarousel = () => import('../../components/section/beauty-creator/Carousel.vue' /* webpackChunkName: "components/section-beauty-creator-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyCreatorsInspireTrendGallery = () => import('../../components/section/beauty-creators-inspire/TrendGallery.vue' /* webpackChunkName: "components/section-beauty-creators-inspire-trend-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyCreatorsInspireTrends = () => import('../../components/section/beauty-creators-inspire/Trends.vue' /* webpackChunkName: "components/section-beauty-creators-inspire-trends" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyInnovatorGallery = () => import('../../components/section/beauty-innovator/Gallery.vue' /* webpackChunkName: "components/section-beauty-innovator-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyInnovatorJuries = () => import('../../components/section/beauty-innovator/Juries.vue' /* webpackChunkName: "components/section-beauty-innovator-juries" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyStreamArticleGrid = () => import('../../components/section/beauty-stream/ArticleGrid.vue' /* webpackChunkName: "components/section-beauty-stream-article-grid" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyStreamCommunity = () => import('../../components/section/beauty-stream/Community.vue' /* webpackChunkName: "components/section-beauty-stream-community" */).then(c => wrapFunctional(c.default || c))
export const SectionBeautyStreamTrends = () => import('../../components/section/beauty-stream/Trends.vue' /* webpackChunkName: "components/section-beauty-stream-trends" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageCarouselSectionLeft = () => import('../../components/section/campaign-page/CarouselSectionLeft.vue' /* webpackChunkName: "components/section-campaign-page-carousel-section-left" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageCarouselSectionRight = () => import('../../components/section/campaign-page/CarouselSectionRight.vue' /* webpackChunkName: "components/section-campaign-page-carousel-section-right" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageGallerySection = () => import('../../components/section/campaign-page/GallerySection.vue' /* webpackChunkName: "components/section-campaign-page-gallery-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageLinkSection = () => import('../../components/section/campaign-page/LinkSection.vue' /* webpackChunkName: "components/section-campaign-page-link-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageOverviewSection = () => import('../../components/section/campaign-page/OverviewSection.vue' /* webpackChunkName: "components/section-campaign-page-overview-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageTableOfContents = () => import('../../components/section/campaign-page/TableOfContents.vue' /* webpackChunkName: "components/section-campaign-page-table-of-contents" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageTextPanelsSection = () => import('../../components/section/campaign-page/TextPanelsSection.vue' /* webpackChunkName: "components/section-campaign-page-text-panels-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageTextSection = () => import('../../components/section/campaign-page/TextSection.vue' /* webpackChunkName: "components/section-campaign-page-text-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageTopicToutSection = () => import('../../components/section/campaign-page/TopicToutSection.vue' /* webpackChunkName: "components/section-campaign-page-topic-tout-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCampaignPageVideoSection = () => import('../../components/section/campaign-page/VideoSection.vue' /* webpackChunkName: "components/section-campaign-page-video-section" */).then(c => wrapFunctional(c.default || c))
export const SectionCarouselBottom = () => import('../../components/section/carousel/Bottom.vue' /* webpackChunkName: "components/section-carousel-bottom" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialBackgroundColorSection = () => import('../../components/section/editorial/BackgroundColorSection.vue' /* webpackChunkName: "components/section-editorial-background-color-section" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialBeautyCreatorsTalkArticlesSection = () => import('../../components/section/editorial/BeautyCreatorsTalkArticlesSection.vue' /* webpackChunkName: "components/section-editorial-beauty-creators-talk-articles-section" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialContents = () => import('../../components/section/editorial/Contents.vue' /* webpackChunkName: "components/section-editorial-contents" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialSeasonCopySection = () => import('../../components/section/editorial/SeasonCopySection.vue' /* webpackChunkName: "components/section-editorial-season-copy-section" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialStylesSection = () => import('../../components/section/editorial/StylesSection.vue' /* webpackChunkName: "components/section-editorial-styles-section" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialTrend = () => import('../../components/section/editorial/Trend.vue' /* webpackChunkName: "components/section-editorial-trend" */).then(c => wrapFunctional(c.default || c))
export const SectionEditorialTrendsSection = () => import('../../components/section/editorial/TrendsSection.vue' /* webpackChunkName: "components/section-editorial-trends-section" */).then(c => wrapFunctional(c.default || c))
export const SectionForCustomerPageBeautyArticlesSection = () => import('../../components/section/for-customer/PageBeautyArticlesSection.vue' /* webpackChunkName: "components/section-for-customer-page-beauty-articles-section" */).then(c => wrapFunctional(c.default || c))
export const SectionForCustomerPageBeautyConsultationSection = () => import('../../components/section/for-customer/PageBeautyConsultationSection.vue' /* webpackChunkName: "components/section-for-customer-page-beauty-consultation-section" */).then(c => wrapFunctional(c.default || c))
export const SectionForProfessionalPageAcademySection = () => import('../../components/section/for-professional/PageAcademySection.vue' /* webpackChunkName: "components/section-for-professional-page-academy-section" */).then(c => wrapFunctional(c.default || c))
export const SectionForProfessionalPageOverviewSection = () => import('../../components/section/for-professional/PageOverviewSection.vue' /* webpackChunkName: "components/section-for-professional-page-overview-section" */).then(c => wrapFunctional(c.default || c))
export const SectionForProfessionalPageToolsSection = () => import('../../components/section/for-professional/PageToolsSection.vue' /* webpackChunkName: "components/section-for-professional-page-tools-section" */).then(c => wrapFunctional(c.default || c))
export const SectionGalleryColumnsPerRow = () => import('../../components/section/gallery/3ColumnsPerRow.vue' /* webpackChunkName: "components/section-gallery-columns-per-row" */).then(c => wrapFunctional(c.default || c))
export const SectionGalleryColumns = () => import('../../components/section/gallery/4Columns.vue' /* webpackChunkName: "components/section-gallery-columns" */).then(c => wrapFunctional(c.default || c))
export const SectionGallerySingleRow = () => import('../../components/section/gallery/SingleRow.vue' /* webpackChunkName: "components/section-gallery-single-row" */).then(c => wrapFunctional(c.default || c))
export const SectionHomeInformationCarousel = () => import('../../components/section/home/InformationCarousel.vue' /* webpackChunkName: "components/section-home-information-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionHomeProducts = () => import('../../components/section/home/Products.vue' /* webpackChunkName: "components/section-home-products" */).then(c => wrapFunctional(c.default || c))
export const SectionHomeRecommendations = () => import('../../components/section/home/Recommendations.vue' /* webpackChunkName: "components/section-home-recommendations" */).then(c => wrapFunctional(c.default || c))
export const SectionHomeTools = () => import('../../components/section/home/Tools.vue' /* webpackChunkName: "components/section-home-tools" */).then(c => wrapFunctional(c.default || c))
export const SectionHomeTrends = () => import('../../components/section/home/Trends.vue' /* webpackChunkName: "components/section-home-trends" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationPageSection = () => import('../../components/section/inspiration/PageSection.vue' /* webpackChunkName: "components/section-inspiration-page-section" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaAtBottom = () => import('../../components/section/media/AtBottom.vue' /* webpackChunkName: "components/section-media-at-bottom" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaLeft = () => import('../../components/section/media/Left.vue' /* webpackChunkName: "components/section-media-left" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaRight = () => import('../../components/section/media/Right.vue' /* webpackChunkName: "components/section-media-right" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaRightMerged = () => import('../../components/section/media/RightMerged.vue' /* webpackChunkName: "components/section-media-right-merged" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaWithShadow = () => import('../../components/section/media/WithShadow.vue' /* webpackChunkName: "components/section-media-with-shadow" */).then(c => wrapFunctional(c.default || c))
export const SectionMediaWithShadowNarrow = () => import('../../components/section/media/WithShadowNarrow.vue' /* webpackChunkName: "components/section-media-with-shadow-narrow" */).then(c => wrapFunctional(c.default || c))
export const SectionNewsAndEventsPageFeaturedSection = () => import('../../components/section/news-and-events/PageFeaturedSection.vue' /* webpackChunkName: "components/section-news-and-events-page-featured-section" */).then(c => wrapFunctional(c.default || c))
export const SectionProductRangeShowcase = () => import('../../components/section/product-range/Showcase.vue' /* webpackChunkName: "components/section-product-range-showcase" */).then(c => wrapFunctional(c.default || c))
export const SectionStyleImagesSection = () => import('../../components/section/style/ImagesSection.vue' /* webpackChunkName: "components/section-style-images-section" */).then(c => wrapFunctional(c.default || c))
export const SectionStyleProductsUsed = () => import('../../components/section/style/ProductsUsed.vue' /* webpackChunkName: "components/section-style-products-used" */).then(c => wrapFunctional(c.default || c))
export const SectionStyleProductsUsedSection = () => import('../../components/section/style/ProductsUsedSection.vue' /* webpackChunkName: "components/section-style-products-used-section" */).then(c => wrapFunctional(c.default || c))
export const SectionStyleVideosSection = () => import('../../components/section/style/VideosSection.vue' /* webpackChunkName: "components/section-style-videos-section" */).then(c => wrapFunctional(c.default || c))
export const SectionUltistCarousel = () => import('../../components/section/ultist/Carousel.vue' /* webpackChunkName: "components/section-ultist-carousel" */).then(c => wrapFunctional(c.default || c))
export const SectionUltistCreator = () => import('../../components/section/ultist/Creator.vue' /* webpackChunkName: "components/section-ultist-creator" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleCarouselSection = () => import('../../components/section/inspiration/article/CarouselSection.vue' /* webpackChunkName: "components/section-inspiration-article-carousel-section" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleCarouselSectionContents = () => import('../../components/section/inspiration/article/CarouselSectionContents.vue' /* webpackChunkName: "components/section-inspiration-article-carousel-section-contents" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleImageArticleLeft = () => import('../../components/section/inspiration/article/ImageArticleLeft.vue' /* webpackChunkName: "components/section-inspiration-article-image-article-left" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleImageArticleRight = () => import('../../components/section/inspiration/article/ImageArticleRight.vue' /* webpackChunkName: "components/section-inspiration-article-image-article-right" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleText = () => import('../../components/section/inspiration/article/Text.vue' /* webpackChunkName: "components/section-inspiration-article-text" */).then(c => wrapFunctional(c.default || c))
export const SectionInspirationArticleTitle = () => import('../../components/section/inspiration/article/Title.vue' /* webpackChunkName: "components/section-inspiration-article-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
